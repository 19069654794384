.action-btn{
	span{
		a{
			padding:0 1rem;	
		}	
	}	
}
.job-table{
	tr{
		td{
			font-weight:600!important;	
		}	
	}	
}